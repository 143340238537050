<template>
  <div class="u__background--white">
    <Content>
      <FlexContainer padding="normal">
        <FlexItem padding="normal" :grow="true">
          <p>We're sorry, the page you're looking for could not be found.</p>
          <p v-if="false">
            If you think something is broken then please don't hesitate to
            <router-link to="/contact"> contact us </router-link>.
          </p>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
export default {}
</script>
